import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import moment from "moment/moment";
import MDEditor from "@uiw/react-md-editor";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FormButton from "./form-button";
import ApiService from "../api";

function TicketUpdate({ ticket_update, is_first }) {
  const { t, i18n } = useTranslation();
  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    if (
      ticket_update.old_assigned_user !== ticket_update.new_assigned_user ||
      ticket_update.old_ticket_status !== ticket_update.new_ticket_status ||
      ticket_update.old_ticket_type !== ticket_update.new_ticket_type ||
      ticket_update.old_ticket_priority !== ticket_update.new_ticket_priority
    ) {
      setShowActions(true);
    }
  });

  return (
    <div
      className={`border ${
        ticket_update.is_internal ? "border-red-800" : "border-gray-300"
      } mt-8 bg-white`}
    >
      <div className={`sm:text-md flex flex-col text-sm text-gray-800`}>
        <div
          className={`p-2 ${
            ticket_update.is_internal ? "bg-red-700 text-white" : "bg-gray-200"
          }`}
        >
          <FontAwesomeIcon icon={solid("ticket")} className={`mr-2`} />
          <Trans
            i18nKey={
              is_first ? "ticket_update.started" : "ticket_update.updated"
            }
            values={{
              name: ticket_update.user.name,
              date: moment(ticket_update.created_at).format(
                "MMMM Do YYYY HH:mm",
              ),
            }}
          />
        </div>
        {ticket_update.content && (
          <div className={`p-4`}>
            <MDEditor.Markdown source={ticket_update.content} />
          </div>
        )}
        {ticket_update.filename && (
          <div className={`p-4`}>
            <FormButton
              onClick={() => {
                const filename = ticket_update.filename.split("/").pop();
                if (filename) {
                  ApiService.downloadFile(ticket_update.download_url).then(
                    (response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data]),
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", filename);
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    },
                  );
                }
              }}
              label={"Download Attachment"}
            />
          </div>
        )}
        {showActions && (
          <div
            className={`flex flex-col gap-1 p-2 pt-2.5 ${
              ticket_update.is_internal
                ? "bg-red-700 text-white"
                : "bg-gray-200"
            }`}
          >
            {ticket_update.new_assigned_user &&
              ticket_update.old_assigned_user !==
                ticket_update.new_assigned_user && (
                <span className="">
                  <FontAwesomeIcon
                    icon={solid("arrow-right")}
                    className={`mr-2 ml-1`}
                  />
                  <Trans
                    i18nKey="ticket_update.assigned_user_changed"
                    values={{ name: ticket_update.new_assigned_user.name }}
                  />
                </span>
              )}
            {ticket_update.new_ticket_status &&
              ticket_update.old_ticket_status !==
                ticket_update.new_ticket_status && (
                <span className="">
                  <FontAwesomeIcon
                    icon={solid("arrow-right")}
                    className={`mr-2 ml-1`}
                  />
                  <Trans
                    i18nKey="ticket_update.status_changed"
                    values={{ status: ticket_update.new_ticket_status.name }}
                  />
                </span>
              )}
            {ticket_update.new_ticket_type &&
              ticket_update.old_ticket_type !==
                ticket_update.new_ticket_type && (
                <span className="">
                  <FontAwesomeIcon
                    icon={solid("arrow-right")}
                    className={`mr-2 ml-1`}
                  />
                  <Trans
                    i18nKey="ticket_update.type_changed"
                    values={{ type: ticket_update.new_ticket_type.name }}
                  />
                </span>
              )}
            {ticket_update.new_ticket_priority &&
              ticket_update.old_ticket_priority !==
                ticket_update.new_ticket_priority && (
                <span className="">
                  <FontAwesomeIcon
                    icon={solid("arrow-right")}
                    className={`mr-2 ml-1`}
                  />
                  <Trans
                    i18nKey="ticket_update.priority_changed"
                    values={{
                      priority: ticket_update.new_ticket_priority.name,
                    }}
                  />
                </span>
              )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TicketUpdate;
