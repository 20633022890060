import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { Sanctum } from "react-sanctum";
import { sanctumConfig } from "./constant";
import { BrowserRouter } from "react-router-dom";
import Nav from "./components/nav";
import Foot from "./components/foot";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmojiModal from "./components/emoji-modal";
import Loading from "./pages/Loading";

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.Suspense fallback={<Loading />}>
      <Provider store={store}>
        <Sanctum config={sanctumConfig}>
          <div className="flex h-screen flex-col justify-between">
            <EmojiModal />
            <ToastContainer position="bottom-right" theme="dark" />
            <Nav />
            <div className={"mb-auto"}>
              <App />
            </div>
            <Foot />
          </div>
        </Sanctum>
      </Provider>
    </React.Suspense>
  </BrowserRouter>,
);
