import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";

function Warning({ message }) {
  return (
    <div className={'flex flex-row mb-8 border border-yellow-300 bg-yellow-400 p-2 rounded'}>
      <FontAwesomeIcon size="xl" icon={solid('circle-exclamation')} className={`mr-2 p-0.5`} />
      <strong>{message}</strong>
    </div>
  );
}

export default Warning;