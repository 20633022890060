import React, { useEffect } from "react";
import TextInput from "../components/text-input";
import FormButton from "../components/form-button";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSanctum } from "react-sanctum";
import ApiService from "../api";

function ResetPassword() {
  const { authenticated } = useSanctum();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (authenticated === true) {
      navigate("/dashboard");
    }
  }, [authenticated]);

  function handleSubmit(event) {
    event.preventDefault();
    let errs = validateForm();
    if (errs.length > 0) {
      errs.forEach((err) => toast.error(err));
      return;
    }
    setIsLoading(true);
    ApiService.resetPassword(
      searchParams.get("token"),
      email,
      password,
      passwordConfirmation,
    )
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setSent(true);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  function validateForm() {
    const errs = [];
    if (email.length < 1) {
      errs.push(t("forgot_password.email_error"));
    }
    return errs;
  }

  return (
    <section className="body-font relative bg-white text-black">
      <div className="container mx-auto px-5 py-24">
        <div className="mb-12 flex w-full flex-col text-center">
          {!sent && (
            <React.Fragment>
              <h1 className="title-font mb-4 text-2xl font-medium text-black sm:text-3xl">
                {t("reset_password.h1")}
              </h1>
              <p className="mx-auto leading-relaxed lg:w-1/2">
                {t("reset_password.message")}
              </p>
            </React.Fragment>
          )}
        </div>
        <div className="mx-auto md:w-2/3 lg:w-1/2">
          {!sent ? (
            <div className="flex flex-wrap gap-4">
              <TextInput
                id="email"
                name="email"
                label={t("email")}
                value={email}
                type={"email"}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
              />
              <TextInput
                id="password"
                name="password"
                label={t("password")}
                value={password}
                type={"password"}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
              />
              <TextInput
                id="passwordConfirmation"
                name="passwordConfirmation"
                label={t("password_confirmation")}
                value={passwordConfirmation}
                type={"password"}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                disabled={isLoading}
              />
              <FormButton
                onClick={handleSubmit}
                label={t("reset_password.button_text")}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <div className="text-center">
              <p className="text-lg font-bold text-green-600">
                {t("reset_password.success_message_1")}
              </p>
              <Link
                to={"https://welfordmedia.co.uk/contact"}
                className="text-sm text-gray-400"
              >
                {t("reset_password.success_message_2")}
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
