import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Emoji from "../components/emoji";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

function TicketCard({ ticket }) {
  const { t } = useTranslation();
  return (
    <Link to={'/tickets/' + ticket.id}>
      <div className={`relative w-full border border-gray-200 bg-white hover:bg-gray-50 mb-6`} style={{ zIndex: 0 }}>
        <div className="font-bold py-2 px-3 flex flex-row justify-between">
          <div className="overflow-hidden text-ellipsis">
            <span className="whitespace-nowrap">#{ticket.id} - {ticket.subject}</span>
            <p className="text-xs font-normal">
              <FontAwesomeIcon
                icon={solid('house')}
                className="mr-1 text-gray-500"
                fixedWidth 
              />
              <span className="font-semibold">{ticket.organisation ? ticket.organisation.name : t('unknown')}</span>
              </p>
            <p className="text-xs font-normal">
              <FontAwesomeIcon
                icon={solid('list-check')}
                className="mr-1 text-gray-500"
                fixedWidth 
              />
              <span className="font-semibold">{ticket.project ? ticket.project.name : t('unknown')}</span>
              </p>
          </div>
          <div className="text-right flex flex-col text-xs whitespace-nowrap gap-1">
            <div>
              {ticket.ticket_status.icon} {ticket.ticket_status.name}
            </div>
            <div>
              {ticket.ticket_type.icon} {ticket.ticket_type.name}
            </div>
            <div>
              Updated: {moment(ticket.updated_at).format('D MMM YY H:m')}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default TicketCard;