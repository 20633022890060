import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiService from "../api";
import { Link, useParams } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import Loading from "../components/loading";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import TicketUpdate from "../components/ticket-update";
import Warning from "../components/warning";
import AuthorisedComponent from "../components/authorised-component";
import TicketUpdateEditor from "../components/ticket-update-editor";
import Emoji from "../components/emoji";
import BackButton from "../components/back-button";
import { useSanctum } from "react-sanctum";

function Ticket() {
  const { user } = useSanctum();
  const { t, i18n } = useTranslation();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const { ticket_id } = useParams();

  useEffect(() => {
    ApiService.getTicket(ticket_id).then((r) => {
      setTicket(r);
      setLoading(false);
    });
  }, []);

  if (ticket) {
    return (
      <section className="body-font relative bg-white text-black">
        <div className="container mx-auto px-5 py-24">
          <BackButton h1_name={"dashboard"} />
          <div className="mb-8 flex flex-col items-start justify-start sm:flex-row sm:items-center sm:justify-between md:mb-4">
            <h1 className="title-font text-2xl font-medium text-black sm:text-3xl">
              #{ticket.id} - {ticket.subject}
            </h1>
            <p className={`text-xs`}>
              {t("created_at")}:{" "}
              {moment(ticket.created_at).format("MMM Do YYYY HH:mm")}
            </p>
          </div>
          <div className="flex flex-row" data-color-mode="light">
            <div className="sm:text-md w-full p-2 text-sm">
              {!ticket.assigned_user_id && (
                <Warning message={t("ticket.not_assigned_message")} />
              )}

              <div className="my-10 grid grid-cols-4 divide-x-2 divide-solid divide-gray-400">
                <div className={`text-center`}>
                  <p className={`font-bold`}>Type</p>
                  <p className={`mt-2`}>{ticket.ticket_type.name}</p>
                </div>
                <div className={`text-center`}>
                  <p className={`font-bold`}>Status</p>
                  <p className={`mt-2`}>
                    <Emoji
                      label={
                        ticket.ticket_status
                          ? ticket.ticket_status.name
                          : t("unknown")
                      }
                      symbol={
                        ticket.ticket_status ? ticket.ticket_status.icon : "❓"
                      }
                    />
                    {ticket.ticket_status.name}
                  </p>
                </div>
                <div className={`text-center`}>
                  <p className={`font-bold`}>Priority</p>
                  <p className={`mt-2`}>{ticket.ticket_priority.name}</p>
                </div>
                <div className={`text-center`}>
                  <p className={`font-bold`}>Assignee</p>
                  <p className={`mt-2`}>
                    {ticket.assigned_user
                      ? ticket.assigned_user.name
                      : "Unassigned"}
                  </p>
                </div>
              </div>

              <TicketUpdate
                ticket_update={ticket.ticket_updates[0]}
                is_first={true}
              />

              {!ticket.assigned_user_id ? (
                <div className="mt-12 flex flex-col">
                  <AuthorisedComponent
                    ticket_id={ticket.id}
                    requiredRole={"agent"}
                    component={TicketUpdateEditor}
                    force_internal={true}
                    initial_assignee={ticket.assigned_user_id || ""}
                    initial_status={ticket.ticket_status_id || ""}
                    initial_priority={ticket.ticket_priority_id || ""}
                    initial_type={ticket.ticket_type_id || ""}
                  />
                </div>
              ) : (
                <div className="flex flex-col">
                  <h2
                    className={`mt-12 text-xl font-medium text-black sm:text-2xl`}
                  >
                    Ticket Updates
                  </h2>
                  {ticket.ticket_updates.map((ticket_update, index) => {
                    if (index !== 0) {
                      return (
                        <TicketUpdate
                          ticket_update={ticket_update}
                          key={index}
                        />
                      );
                    }
                  })}
                  <div className="mt-8">
                    <TicketUpdateEditor
                      ticket_id={ticket.id}
                      force_internal={false}
                      initial_assignee={ticket.assigned_user_id || ""}
                      initial_status={ticket.ticket_status_id || ""}
                      initial_priority={ticket.ticket_priority_id || ""}
                      initial_type={ticket.ticket_type_id || ""}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <div className={`mt-12`}>
        <Loading />
      </div>
    );
  }
}

export default Ticket;
