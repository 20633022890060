
function FormButton({isLoading, style, disabled, label, button_type, onClick}) {

  let classes = isLoading || disabled ? 'bg-white text-gray-400 border-gray-400 hover:cursor-not-allowed' : 'bg-black text-white border-black hover:bg-white hover:text-black';
  if (style === 'red') {
    classes = isLoading || disabled ? 'bg-white text-red-300 border-red-300 hover:cursor-not-allowed' : 'bg-red-700 text-white border-red-800 hover:bg-white hover:text-red-700';
  }

  classes += ' flex justify-center w-full md:w-auto text-base border py-3 px-8 focus:outline-none text-lg transition-all duration-200 ease-in-out';

  return (
    <div>
      <button disabled={isLoading || disabled} type={button_type ?? 'button'} onClick={onClick} className={classes}>
        { isLoading &&
        <svg className="animate-spin -ml-1 mr-3 mt-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        }
        {label}
      </button>
    </div>
  );
}

export default FormButton;