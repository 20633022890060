import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Markdown from "./markdown";
import SelectInput from "./select-input";
import ApiService from "../api";
import AuthorisedComponent from "./authorised-component";
import FormButton from "./form-button";
import FileUploadInput from "./file-upload-input";
import Loading from "./loading";

const TicketUpdateEditor = function ({
  ticket_id,
  force_internal,
  initial_assignee,
  initial_status,
  initial_type,
  initial_priority,
}) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [is_internal, setIsInternal] = useState(false);
  const [assignee, setAssignee] = useState(initial_assignee);
  const [status, setStatus] = useState(initial_status);
  const [type, setType] = useState(initial_type);
  const [priority, setPriority] = useState(initial_priority);
  const [content, setContent] = useState("");
  const [file, setFile] = useState(null);

  const [assignees, setAssignees] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [types, setTypes] = useState([]);
  const [priorities, setPriorities] = useState([]);

  const InternalToggle = function () {
    return (
      <div className="block min-h-[1.4rem] justify-end pl-[1.5rem]">
        <label
          className="inline-block select-none pr-[0.15rem] pt-[0.15rem] hover:cursor-pointer"
          htmlFor="checkboxChecked"
        >
          Internal
        </label>
        <input
          disabled={force_internal}
          className="relative float-left mt-[0.15rem] mr-[6px] -ml-[1.5rem] h-[1.125rem] w-[1.125rem] cursor-pointer"
          type="checkbox"
          id="checkboxChecked"
          checked={is_internal}
          onChange={(e) => setIsInternal(e.target.checked)}
        />
        <label className="checkbox-tick" htmlFor="checkboxChecked"></label>
      </div>
    );
  };

  const postUpdate = function () {
    ApiService.createTicketUpdate(
      ticket_id,
      content,
      file,
      assignee,
      status,
      type,
      priority,
      is_internal,
    ).then((r) => {
      if (r) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    setIsInternal(force_internal || false);
    ApiService.getTicketStatuses().then((r) => {
      if (Array.isArray(r)) {
        setStatuses(
          r.map((status) => {
            return { value: status.id, label: status.icon + " " + status.name };
          }),
        );
      }
    });
    ApiService.getTicketTypes().then((r) => {
      if (Array.isArray(r)) {
        setTypes(
          r.map((type) => {
            return { value: type.id, label: type.icon + " " + type.name };
          }),
        );
      }
    });
    ApiService.getTicketPriorities().then((r) => {
      if (Array.isArray(r)) {
        setPriorities(
          r.map((priority) => {
            return {
              value: priority.id,
              label: priority.icon + " " + priority.name,
            };
          }),
        );
      }
    });
    ApiService.getTicketAssignees(ticket_id).then((r) => {
      if (Array.isArray(r)) {
        setAssignees(
          r.map((assignee) => {
            return {
              value: assignee.id,
              label:
                assignee.first_name +
                " " +
                assignee.last_name +
                " (" +
                assignee.email +
                ")",
            };
          }),
        );
        if (assignee === "") {
          setAssignee(r[0].id);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (
      statuses.length &&
      types.length &&
      priorities.length &&
      assignees.length
    ) {
      setLoading(false);
    }
  }, [statuses, types, priorities, assignees]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div
        className={`border ${
          is_internal ? "border-red-800" : "border-gray-300"
        } bg-white`}
      >
        <div className={`sm:text-md flex flex-col text-sm text-gray-800`}>
          <div
            className={`flex flex-row justify-between p-2 ${
              is_internal ? "bg-red-700 text-white" : "bg-gray-200"
            }`}
          >
            <div className={`pr-8`}>
              <FontAwesomeIcon icon={solid("comment")} className={`mr-2`} />
              {is_internal
                ? t("ticket_update.internal")
                : t("ticket_update.update_title")}
              {is_internal && force_internal && (
                <div className={"mt-2 p-0 text-xs md:ml-2"}>
                  {t("ticket_update.internal_forced_note")}
                </div>
              )}
            </div>
            <AuthorisedComponent
              requiredRole={"agent"}
              component={InternalToggle}
            />
          </div>
          <div className={`p-2`}>
            <Markdown value={content} onChange={(e) => setContent(e)} />
          </div>
          <div
            className={`grid grid-cols-1 gap-2 px-2 px-4 pb-3 md:grid-cols-6`}
          >
            <div className="col-span-6 md:col-span-2">
              <SelectInput
                label={t("priority")}
                value={priority}
                options={priorities}
                onChange={(e) => setPriority(e.target.value)}
                name={"priority"}
              />
            </div>
            <div className="col-span-6 md:col-span-2">
              <SelectInput
                label={t("status")}
                value={status}
                options={statuses}
                onChange={(e) => setStatus(e.target.value)}
                name={"status"}
              />
            </div>
            <div className="col-span-6 md:col-span-2">
              <SelectInput
                label={t("type")}
                value={type}
                options={types}
                onChange={(e) => setType(e.target.value)}
                name={"type"}
              />
            </div>
            <div className="col-span-6 md:col-span-3">
              <SelectInput
                label={t("assignee")}
                value={assignee}
                options={assignees}
                onChange={(e) => setAssignee(e.target.value)}
                name={"assignee"}
              />
            </div>
            <div className="col-span-6 md:col-span-3">
              <FileUploadInput
                onChange={(e) => {
                  setFile(e.target.files[0] || null);
                }}
                label={t("ticket_update.files")}
              />
            </div>
            <div className={`col-span-4`}>
              <FormButton
                disabled={!assignee || !status || !type || !priority}
                style={is_internal ? "red" : null}
                className={"w-full"}
                label={t("ticket_update.post_update")}
                onClick={postUpdate}
                name={"post_update"}
                id={"post_update"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default TicketUpdateEditor;
