import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import ApiService from "../../api";
import AddUser from "./AddUser";

function Users() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    // Fetch the list of users
    setLoading(true);
    ApiService.getUsers().then((response) => {
      setUsers(response);
      setLoading(false);
    });
  };

  const addUser = () => {
    setSelectedUser({});
    setEditing(true);
  };

  const editUser = (id) => {
    const user = users.find((user) => user.id === id);
    if (user) {
      setSelectedUser(user);
      setEditing(true);
    }
  };

  const deleteUser = (id) => {
    const user = users.find((user) => user.id === id);
    if (user) {
      ApiService.deleteUser(id).then(() => {
        getUsers();
      });
    }
  };

  if (loading) {
    return (
      <div className="my-8">
        <Loading />
      </div>
    );
  }

  if (editing) {
    return (
      <AddUser
        selectedUser={selectedUser}
        cancel={() => {
          setEditing(false);
          setSelectedUser({});
          getUsers();
        }}
      />
    );
  }

  return (
    <section className="flex flex-col gap-5">
      <div className="mt-8 mb-3 flex justify-end">
        <button
          onClick={addUser.bind(this)}
          className="border border-black bg-black px-4 py-2 text-white transition-all duration-200 ease-in-out hover:bg-white hover:text-black"
        >
          {t("admin.add-user")}
        </button>
      </div>
      <ul>
        {users.map((user, index) => {
          return (
            <li
              key={user.id}
              className="mb-5 grid grid-cols-1 gap-3 border border-gray-300 bg-white px-2 py-3 text-sm text-gray-600 sm:grid-cols-6"
            >
              <div className="col-span-1 flex flex-col truncate px-3 sm:col-span-2">
                <label className="text-sm text-gray-500">Name</label>
                <p className="truncate">
                  {user.first_name} {user.last_name}
                </p>
                <p className="truncate text-xs">{user.email}</p>
              </div>
              <div className="col-span-1 flex flex-col truncate px-3">
                <label className="text-sm text-gray-500">Role</label>
                <p className="truncate">
                  {user.is_agent ? "Agent" : user.is_admin ? "Admin" : "User"}
                </p>
              </div>
              <div className="col-span-1 flex flex-col truncate px-3 sm:col-span-2">
                <label className="text-sm text-gray-500">Organisations</label>
                <p className="truncate">
                  {user.organisations.length === 0 && "-"}
                  {user.organisations.map((organisation, index) => {
                    return (
                      <span key={organisation.id}>
                        {organisation.name}
                        {index < user.organisations.length - 1 ? ", " : ""}
                      </span>
                    );
                  })}
                </p>
              </div>
              <div className="col-span-1 flex flex-row items-center justify-between gap-1 truncate px-3 sm:justify-end">
                <FontAwesomeIcon
                  title={t("edit")}
                  icon={solid("edit")}
                  size={"xl"}
                  className={`mr-2 cursor-pointer text-blue-700`}
                  onClick={editUser.bind(this, user.id)}
                />
                {!user.is_admin && (
                  <FontAwesomeIcon
                    title={t("delete")}
                    icon={solid("trash-alt")}
                    size={"xl"}
                    className={`cursor-pointer text-red-700`}
                    onClick={deleteUser.bind(this, user.id)}
                  />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default Users;
