import TextInput from "../components/text-input";
import FormButton from "../components/form-button";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const { t, i18n } = useTranslation();

  async function handleSubmit(event) {
    event.preventDefault();
    let errs = validateForm()
    if (errs.length > 0) {
      errs.forEach(err => toast.error(err));
      return;
    }
    setIsLoading(true)

  }

  function validateForm() {
    const errs = [];
    if (name.length < 1) {
      errs.push(t("index.name_error"));
    }
    if (email.length < 1) {
      errs.push(t("index.email_error"));
    }
    return errs;
  }

  return (
    <section className="text-black bg-white body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-black">{t("index.h1")}</h1>
          <p className="lg:w-1/2 mx-auto leading-relaxed text-lg sm:text-xl">
            {t("index.message_1")}
          </p>
          <p className="mt-5 lg:w-1/2 mx-auto leading-relaxed text-base">
            {t("index.message_2")}
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          { !sent ? (
          <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
            <TextInput id="name" name="name" label={t('name')} value={name} type={"text"} onChange={e => setName(e.target.value)} disabled={isLoading} />
            <TextInput id="email" name="email" label={t("email")} value={email} type={"email"} onChange={e => setEmail(e.target.value)} disabled={isLoading} />
            <div className="pt-4">
              <FormButton type={'submit'} onClick={handleSubmit} label={t("submit")} isLoading={isLoading} />
            </div>
          </form>
          ) : (
            <div className="text-center">
              <p className="text-lg text-green-600 font-bold">{t("index.request_success")}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Home;