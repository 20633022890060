import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSanctum } from "react-sanctum";
import TabGroup from '../../components/tab-group';
import Users from './Users';
import Organisations from './Organisations';
import TicketTypes from './TicketTypes';
import TicketStatuses from './TicketStatuses';
import TicketPriorities from './TicketPriorities';
import System from './System';
import Billing from './Billing';
import ApiService from '../../api';

// This component is used to display the Admin page.
// It displays a list of tabs on the left, and the content corresponding to the selected tab on the right.
// The list of tabs and their corresponding components are defined in the `components` array.
// The component will also redirect to the login page if the user is not authenticated.
function Admin() {
    const { t } = useTranslation(); // Hook to get the translation function
    const { authenticated, user } = useSanctum(); // Hook to get the authentication status and the current user
    const navigate = useNavigate(); // Hook to navigate to other pages
    const [pageIndex, setPageIndex] = useState(0); // Hook to store the index of the selected tab

    // States for each of the tabs
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(true);

    const [ticketTypes, setTicketTypes] = useState([]);
    const [ticketTypesLoading, setTicketTypesLoading] = useState(true);

    const [ticketStatuses, setTicketStatuses] = useState([]);
    const [defaultTicketStatus, setDefaultTicketStatus] = useState(0);
    const [ticketStatusesLoading, setTicketStatusesLoading] = useState(true);

    const [ticketPriorities, setTicketPriorities] = useState([]);
    const [defaultTicketPriority, setDefaultTicketPriority] = useState(0);
    const [ticketPrioritiesLoading, setTicketPrioritiesLoading] = useState(true);

    const [system, setSystem] = useState({});
    const [systemLoading, setSystemLoading] = useState(true);

    const [billing, setBilling] = useState({});
    const [billingLoading, setBillingLoading] = useState(true);

    // Array of components to display in the admin page.
    // Each component is defined by an object containing a label (the tab name) and a component (the component to display)
    const components = useMemo(() => [
        { label: t('admin.orgs_and_projects'), component: <Organisations /> },
        { label: t('users'), component: <Users users={users} setUsers={setUsers} isLoading={usersLoading} setIsLoading={setUsersLoading} /> },
        { label: t('admin.ticket_types'), component: <TicketTypes ticketTypes={ticketTypes} setTicketTypes={setTicketTypes} isLoading={ticketTypesLoading} setIsLoading={setTicketTypesLoading} /> },
        { label: t('admin.ticket_statuses'), component: <TicketStatuses ticketStatuses={ticketStatuses} setTicketStatuses={setTicketStatuses} defaultTicketStatus={defaultTicketStatus} setDefaultTicketStatus={setDefaultTicketStatus} isLoading={ticketStatusesLoading} setIsLoading={setTicketStatusesLoading} /> },
        { label: t('admin.ticket_priorities'), component: <TicketPriorities ticketPriorities={ticketPriorities} setTicketPriorities={setTicketPriorities} defaultTicketPriority={defaultTicketPriority} setDefaultTicketPriority={setDefaultTicketPriority} isLoading={ticketPrioritiesLoading} setIsLoading={setTicketPrioritiesLoading} /> },
    ], [t, users, usersLoading, ticketTypes, ticketTypesLoading, ticketStatuses, defaultTicketStatus, ticketStatusesLoading, ticketPriorities, defaultTicketPriority, ticketPrioritiesLoading, system, systemLoading, billing, billingLoading]);

    // Use the `useEffect` hook to run some code when the page is loaded
    useEffect(() => {
        // If the user is not authenticated, redirect to the login page
        if (authenticated === false) {
            navigate('/login');
        }

        // If the user is not an admin, redirect to the dashboard
        if (user && !user.is_admin) {
            navigate('/dashboard');
            return;
        }

        //getUsers();
        //getProjects();
        getTicketTypes();
        getTicketStatuses();
        getTicketPriorities();
        //getSystem();
        //getBilling();
    }, [])



    const getTicketTypes = () => {
        // Fetch the list of ticket types
        setTicketTypesLoading(true);
        ApiService.getTicketTypes().then((response) => {
            setTicketTypes(response);
            setTicketTypesLoading(false);
        });
    }

    const getTicketStatuses = () => {
        // Fetch the list of ticket statuses
        setTicketStatusesLoading(true);
        ApiService.getTicketStatuses().then((response) => {
            setTicketStatuses(response);

            const array = response.filter((item) => item.is_default === true);
            const defaultTicketStatus = array.length > 0 ? array[0].id : 0;
            setDefaultTicketStatus(defaultTicketStatus);

            setTicketStatusesLoading(false);
        });
    }

    const getTicketPriorities = () => {
        // Fetch the list of ticket priorities
        setTicketPrioritiesLoading(true);
        ApiService.getTicketPriorities().then((response) => {
            setTicketPriorities(response);

            const array = response.filter((item) => item.is_default === true);
            const defaultTicketPriority = array.length > 0 ? array[0].id : 0;
            setDefaultTicketPriority(defaultTicketPriority);

            setTicketPrioritiesLoading(false);
        });
    }

    const getSystem = () => {
        // Fetch the system configuration
        setSystemLoading(true);
        ApiService.getSystem().then((response) => {
            setSystem(response);
            setSystemLoading(false);
        });
    }

    const getBilling = () => {
        // Fetch the billing information
        setBillingLoading(true);
        ApiService.getBilling().then((response) => {
            setBilling(response);
            setBillingLoading(false);
        });
    }

    // If the user is not authenticated or is not an admin, do not display anything
    if (authenticated === false || user === null || (user && user.is_admin === false)) {
        return null;
    }

    return (
        <section className="text-black bg-white body-font relative">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col sm:flex-row sm:items-center items-start sm:justify-between justify-start mb-8 md:mb-4">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font text-black">{t("admin.h1")}</h1>
                </div>
                <TabGroup tabs={components.map((item) => ({ label: item.label }))} selected={pageIndex} onTabClick={setPageIndex} />
                {components.map((item, index) => (
                    <div key={index} className={index === pageIndex ? '' : 'hidden'}>
                        {item.component}
                    </div>
                ))}
            </div>
        </section>
    );

}

export default Admin;