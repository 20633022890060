import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useTranslation} from "react-i18next";

function BackButton ({h1_name}) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="mb-6 cursor-pointer font-bold text-gray-700 hover:text-gray-900" onClick={() => { navigate(-1) }}>
      <FontAwesomeIcon icon={solid('arrow-left')} className="mr-2" />
      <span className="align-middle">{t('return_to')} {t(h1_name + '.h1')}</span>
    </div>
  );
}

export default BackButton;