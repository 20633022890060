import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import { useSanctum } from "react-sanctum";
import CreateTicket from "./pages/CreateTicket";
import Ticket from "./pages/Ticket";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setBranding } from "./features/branding";
import ApiService from "./api";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import Admin from "./pages/Admin/Admin";
import AddUser from "./pages/Admin/AddUser";
import NotFound from "./pages/404";

function App() {
  const { user } = useSanctum();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    ApiService.getAssets().then((response) => {
      dispatch(setBranding(response));
    });
  }, [dispatch, user]);

  return (
    <SwitchTransition component={null}>
      <CSSTransition key={location.key} timeout={300} classNames="fade">
        <Routes location={location}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/create-ticket" element={<CreateTicket />} />
          <Route exact path={"/tickets/:ticket_id"} element={<Ticket />} />
          <Route exact path={"/admin"} element={<Admin />} />
          <Route exact path={"/admin/add-user"} element={<AddUser />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default App;
