import axios from "axios";
import { toast } from "react-toastify";
import { sanctumConfig } from "./constant";

const api = axios.create({
  baseURL: sanctumConfig.apiUrl,
  headers: {
    "Accept-Language": window.localStorage.getItem("language") || "en",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (
      err.response.data &&
      err.response.data.errors &&
      Array.isArray(err.response.data.errors)
    ) {
      err.response.data.errors.forEach((error) => {
        toast.error(error);
      });
    } else if (err.response.data && err.response.data.message) {
      if (err.response.status === 401) {
        window.location.href = "/login";
        return Promise.reject(err);
      }
      toast.error(err.response.data.message);
    }
    return Promise.reject(err);
  },
);

const getAssets = async () => {
  const response = await api.get("/assets");
  return response.data;
};

const login = async (username, password) => {
  await api.get("/csrf");
  const response = await api.post("/login", {
    email: username,
    password: password,
  });
  console.log(response);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const logout = async () => {
  await api.get("/csrf");
  const response = await api.post("/logout");
  return response.data;
};

const forgotPassword = async (email) => {
  const response = await api.post("/forgot-password", {
    email: email,
  });
  return response.data;
};

const resetPassword = async (token, email, password, password_confirmation) => {
  const response = await api.post("/reset-password", {
    token: token,
    email: email,
    password: password,
    password_confirmation: password_confirmation,
  });
  return response.data;
};

const getCurrentUser = async () => {
  const response = await api.get("/user");
  return response.data;
};

const getUsers = async () => {
  const response = await api.get("/users");
  return response.data;
};

const createOrUpdateUser = async (
  id,
  first_name,
  last_name,
  email,
  role,
  organisation_ids,
) => {
  let response = null;
  if (id === null || id === undefined) {
    response = await api.post("/users", {
      first_name: first_name,
      last_name: last_name,
      email: email,
      role: role,
      organisation_ids: organisation_ids,
    });
  } else {
    response = await api.put("/users/" + id, {
      first_name: first_name,
      last_name: last_name,
      email: email,
      role: role,
      organisation_ids: organisation_ids,
    });
  }
  return response.data;
};

const deleteUser = async (id) => {
  const response = await api.delete("/users/" + id);
  return response.data;
};

const getTicketStatuses = async () => {
  const response = await api.get("/ticket-statuses");
  return response.data;
};

const addTicketStatus = async () => {
  const response = await api.post("/ticket-statuses");
  return response.data;
};

const updateTicketStatuses = async (ticket_statuses) => {
  const response = await api.put("/ticket-statuses", {
    ticket_statuses: ticket_statuses,
  });
  return response.data;
};

const deleteTicketStatus = async (id) => {
  const response = await api.delete("/ticket-statuses/" + id);
  return response.data;
};

const getTicketPriorities = async () => {
  const response = await api.get("/ticket-priorities");
  return response.data;
};

const addTicketPriority = async () => {
  const response = await api.post("/ticket-priorities");
  return response.data;
};

const updateTicketPriorities = async (ticket_priorities) => {
  const response = await api.put("/ticket-priorities", {
    ticket_priorities: ticket_priorities,
  });
  return response.data;
};

const deleteTicketPriority = async (id) => {
  const response = await api.delete("/ticket-priorities/" + id);
  return response.data;
};

const getTicketTypes = async () => {
  const response = await api.get("/ticket-types");
  return response.data;
};

const addTicketType = async () => {
  const response = await api.post("/ticket-types");
  return response.data;
};

const updateTicketTypes = async (ticket_types) => {
  const response = await api.put("/ticket-types", {
    ticket_types: ticket_types,
  });
  return response.data;
};

const deleteTicketType = async (id) => {
  const response = await api.delete("/ticket-types/" + id);
  return response.data;
};

const getOrganisations = async () => {
  const response = await api.get("/organisations");
  return response.data;
};

const getOrganisation = async (id) => {
  const response = await api.get("/organisations/" + id);
  return response.data;
};

const createOrganisation = async (name) => {
  const response = await api.post("/organisations", {
    name: name,
  });
  return response.data;
};

const updateOrganisation = async (id, name) => {
  const response = await api.put("/organisations/" + id, {
    name: name,
  });
  return response.data;
};

const deleteOrganisation = async (id) => {
  const response = await api.delete("/organisations/" + id);
  return response.data;
};

const getProjects = async (id) => {
  const response = await api.get("/projects", {
    params: { organisation_id: id },
  });
  return response.data;
};

const createProject = async (name, organisation_id) => {
  const response = await api.post("/projects", {
    name: name,
    organisation_id: organisation_id,
  });
  return response.data;
};

const updateProject = async (id, name, organisation_id) => {
  const response = await api.put("/projects/" + id, {
    name: name,
    organisation_id: organisation_id,
  });
  return response.data;
};

const deleteProject = async (id) => {
  const response = await api.delete("/projects/" + id);
  return response.data;
};

const getTickets = async (assignee, status, organisation, project) => {
  const response = await api.get("/tickets", {
    params: {
      assignee: assignee,
      status: status,
      organisation: organisation,
      project: project,
    },
  });
  return response.data;
};

const createTicket = async (
  type,
  subject,
  organisation,
  project,
  markdown,
  file,
) => {
  const response = await api.post("/tickets", {
    subject: subject,
    organisation_id: organisation,
    project_id: project,
    content: markdown,
    file: file,
    type: type,
  });
  return response.data;
};

const createTicketUpdate = async (
  ticket_id,
  content = null,
  file = null,
  assigned_user = null,
  ticket_status = null,
  ticket_type = null,
  ticket_priority = null,
  internal = false,
) => {
  if (file) {
    const formData = new FormData();
    formData.append("content", content);
    formData.append("file", file);
    formData.append("assigned_user_id", assigned_user);
    formData.append("ticket_status_id", ticket_status);
    formData.append("ticket_type_id", ticket_type);
    formData.append("ticket_priority_id", ticket_priority);
    formData.append("internal", internal);
    const response = await api.post(`/tickets/${ticket_id}/updates`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } else {
    const response = await api.post(`/tickets/${ticket_id}/updates`, {
      content: content,
      file: file,
      assigned_user_id: assigned_user,
      ticket_status_id: ticket_status,
      ticket_type_id: ticket_type,
      ticket_priority_id: ticket_priority,
      internal: internal,
    });
    return response.data;
  }
};

const getTicket = async (id) => {
  const response = await api.get("/tickets/" + id);
  return response.data;
};

const downloadFile = async (url) => {
  return api.get(url, {
    responseType: "blob",
  });
};

const getTicketAssignees = async (id) => {
  const response = await api.get("/tickets/" + id + "/assignees");
  return response.data;
};

const mandate = async () => {
  const response = await api.get("/gc/mandate");
  return response.data;
};

const subscription = async () => {
  const response = await api.get("/gc/subscription");
  return response.data;
};

const ApiService = {
  getAssets,
  downloadFile,
  login,
  logout,
  forgotPassword,
  resetPassword,
  getCurrentUser,
  getUsers,
  createOrUpdateUser,
  deleteUser,
  getTicketStatuses,
  addTicketStatus,
  updateTicketStatuses,
  deleteTicketStatus,
  getTicketPriorities,
  addTicketPriority,
  updateTicketPriorities,
  deleteTicketPriority,
  getTicketTypes,
  addTicketType,
  deleteTicketType,
  updateTicketTypes,
  getOrganisations,
  getOrganisation,
  createOrganisation,
  updateOrganisation,
  deleteOrganisation,
  getProjects,
  createProject,
  updateProject,
  deleteProject,
  getTickets,
  createTicket,
  createTicketUpdate,
  getTicket,
  getTicketAssignees,
  mandate,
  subscription,
};

export default ApiService;
