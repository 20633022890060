import React, { useState, useEffect } from 'react';
import {useSanctum} from "react-sanctum";

const AuthorisedComponent = ({ component: WrappedComponent, requiredRole, ...restProps }) => {
  const { authenticated, user } = useSanctum();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    (user.is_agent === true || user.is_agent === 1) && setUserRole('agent');
    (user.is_admin === true || user.is_admin === 1) && setUserRole('admin');
  }, [restProps]);

  if (authenticated && (userRole === requiredRole || userRole === 'admin')) {
    return <WrappedComponent {...restProps} />;
  } else {
    return null;
  }
};

export default AuthorisedComponent;