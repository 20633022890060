import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSanctum } from "react-sanctum";
import { useEffect, useState } from "react";
import TextInput from "../../components/text-input";
import SelectInput from "../../components/select-input";
import { MultiSelect } from "react-multi-select-component";
import ApiService from "../../api";
import FormButton from "../../components/form-button";

function AddUser({ selectedUser, cancel }) {
  const { t } = useTranslation();
  const { authenticated, user } = useSanctum();
  const navigate = useNavigate();

  const [id, setId] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user");
  const [organisations, setOrganisations] = useState([]);

  const [orgOptions, setOrgOptions] = useState([]);

  useEffect(() => {
    if (authenticated === false) {
      navigate("/login");
    }

    if (user && !user.is_admin) {
      navigate("/dashboard");
      return;
    }

    ApiService.getOrganisations().then((response) => {
      setOrgOptions(response);
    });
  }, []);

  useEffect(() => {
    if (selectedUser && Object.keys(selectedUser).length > 0) {
      setId(selectedUser.id);
      setFirstname(selectedUser.first_name);
      setLastname(selectedUser.last_name);
      setEmail(selectedUser.email);
      setRole(
        selectedUser.is_agent
          ? "agent"
          : selectedUser.is_admin
          ? "admin"
          : "user",
      );
      if (!selectedUser.is_agent && !selectedUser.is_admin) {
        setOrganisations(
          selectedUser.organisations.map((org) => {
            return { value: org.id, label: org.name };
          }),
        );
      }
    } else {
      setId(null);
      setFirstname("");
      setLastname("");
      setEmail("");
      setRole("user");
      setOrganisations([]);
    }
  }, [selectedUser]);

  const handleFirstNameChange = (e) => {
    setFirstname(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastname(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    if (e.target.value !== "user") {
      setOrganisations([]);
    }
  };

  const saveUser = () => {
    // Remove all error spans
    let errorSpans = document.querySelectorAll("span.text-red-500");
    errorSpans.forEach((span) => {
      span.innerHTML = "";
    });
    ApiService.createOrUpdateUser(
      id,
      firstname,
      lastname,
      email,
      role,
      organisations.map((org) => org.value),
    )
      .then((response) => {
        if (response.id) {
          setFirstname("");
          setLastname("");
          setEmail("");
          setRole("user");
          setOrganisations([]);
          cancel();
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((key) => {
            let el = document.getElementById(`user-${key}`);
            if (el) {
              el.innerHTML = "This field is required";
            }
          });
        }
      });
  };

  return (
    <section className="body-font relative bg-white text-black">
      <div className="container mx-auto space-y-4 py-14 px-5">
        <div className="mb-8 flex flex-col items-start justify-start sm:flex-row sm:items-center sm:justify-between md:mb-4">
          <h1 className="title-font text-2xl font-medium text-black sm:text-3xl">
            {t("admin.add-user")}
          </h1>
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="w-full">
            <TextInput
              label={t("admin.user.firstname")}
              placeholder={t("admin.user.firstname")}
              value={firstname}
              onChange={handleFirstNameChange}
            />
            <span
              id={"user-first_name"}
              className="text-xs italic text-red-500"
            ></span>
          </div>
          <div className="w-full">
            <TextInput
              label={t("admin.user.lastname")}
              placeholder={t("admin.user.lastname")}
              value={lastname}
              onChange={handleLastNameChange}
            />
            <span
              id={"user-last_name"}
              className="text-xs italic text-red-500"
            ></span>
          </div>
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="w-full">
            <TextInput
              type={"email"}
              label={t("email")}
              placeholder={t("email")}
              value={email}
              onChange={handleEmailChange}
            />
            <span
              id={"user-email"}
              className="text-xs italic text-red-500"
            ></span>
          </div>
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="w-full">
            <SelectInput
              multiple={true}
              label={t("role")}
              placeholder={t("admin.user.role")}
              value={role}
              options={[
                { value: "user", label: "User" },
                { value: "agent", label: "Agent" },
                { value: "admin", label: "Admin" },
              ]}
              onChange={handleRoleChange}
            />
            <span
              id={"user-role"}
              className="text-xs italic text-red-500"
            ></span>
          </div>
        </div>
        {role === "user" && (
          <div className="flex flex-col gap-4">
            <label className="text-sm text-gray-500">
              {t("organisations")}
            </label>
            <MultiSelect
              value={organisations}
              onChange={setOrganisations}
              options={orgOptions.map((org) => {
                return { value: org.id, label: org.name };
              })}
            />
            <span
              id={"user-organisation_ids"}
              className="-mt-2.5 text-xs italic text-red-500"
            ></span>
          </div>
        )}
        <div className="flex flex-row gap-4 pt-5">
          <FormButton label={t("save")} onClick={saveUser} />
          <FormButton label={t("cancel")} onClick={cancel} />
        </div>
      </div>
    </section>
  );
}

export default AddUser;
