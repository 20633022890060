import MDEditor, {commands} from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

function Markdown({ id, label, name, value, onChange, disabled }) {
  return (
    <div className="p-2 w-full">
      <div className="flex flex-col" data-color-mode="light">
        <label htmlFor={id} className="leading-7 text-sm text-gray-500">
          {label}
        </label>
        <MDEditor
          id={id}
          preview="edit"
          height={256}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          visibleDragbar={false}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]]
          }}
          commands={[
            commands.bold,
            commands.italic,
            commands.strikethrough,
            commands.unorderedListCommand,
            commands.divider,
            commands.title,
            commands.hr,
            commands.link,
          ]}
          extraCommands={[]}
        />
      </div>
    </div>
  );
}

export default Markdown;