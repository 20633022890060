import React, { useState, useEffect } from 'react';
import Picker from 'emoji-picker-react';
import eventBus from '../eventBus';

function EmojiModal() {
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [uid, setUid] = useState(null);


    useEffect(() => {
        eventBus.on('displayEmojiModal', (e) => {
            setEmojiPickerVisible(true);
            setUid(e.uid);
        });
    }, []);

    return (
        <div tabIndex="-1" aria-hidden="true" className={`${!emojiPickerVisible && 'hidden'} cursor-default fixed top-0 left-0 right-0 z-50 w-full h-full bg-gray-900/75`}>
            <div className="mx-auto relative w-full h-full max-w-2xl md:h-auto p-6 pt-[120px] cursor-default">
                <div className="relative bg-white border-black border-1 rounded shadow-lg cursor-default">
                    <div className="p-2 cursor-default">
                        <Picker
                        width="100%"
                        searchDisabled={false}
                        skinTonesDisabled={true}
                        lazyLoadEmojis={true}
                        onEmojiClick={(event) => {eventBus.dispatch('emojiSelected', {emoji: event.emoji, uid: uid}); setEmojiPickerVisible(false);} }
                        emojiStyle={'native'}
                        previewConfig={{showPreview: false}} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmojiModal;