import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import TextInput from "./text-input";
import ApiService from "../api";
import { useState } from "react";

function Organisation({ organisation, deleteOrganisation, setOrganisationName, addProject, setProjectName, deleteProject }) {

  const [organisationNameTimeout, setOrganisationNameTimeout] = useState(null);

  const handleNameChange = (e) => {
    setOrganisationName(organisation.id, e.target.value);
    clearTimeout(organisationNameTimeout);
    setOrganisationNameTimeout(setTimeout(() => {
      ApiService.updateOrganisation(organisation.id, e.target.value);
      setOrganisationNameTimeout(null);
    }, 3000));
  };

  const handleDeleteOrganisation = () => {
    deleteOrganisation(organisation.id);
  };

  const handleAddProject = () => {
    addProject(organisation.id);
  };

  const handleDeleteProject = (e) => {
    deleteProject(e.target.closest('div').dataset.projectId);
  };

  const Project = ({ project }) => {
    const [projectNameTimeout, setProjectNameTimeout] = useState(null);

    const handleProjectNameChange = (e) => {
      clearTimeout(projectNameTimeout);
      setProjectNameTimeout(setTimeout(() => {
        ApiService.updateProject(project.id, e.target.value, organisation.id);
        setProjectNameTimeout(null);
      }, 3000));
    };

    return (
      <div key={project.id} className="flex items-center justify-between py-4 px-6">
        <div className="w-full pl-3 pt-1">
          <div className="flex gap-2">
            <span className="font-medium w-full">
              <TextInput hasLoading={true} isLoading={projectNameTimeout} value={project.name} onChange={handleProjectNameChange} />
            </span>
          </div>
          <div className="mt-2 flex items-center gap-2">
            <span className="text-gray-400">{project.ticket_count} Tickets</span>
          </div>
        </div>
        <div data-project-id={project.id} className="mt-2 ml-6">
          <FontAwesomeIcon
            onClick={handleDeleteProject}
            className={project.ticket_count === 0 ? 'text-red-500 cursor-pointer' : 'text-grey-400 cursor-not-allowed'}
            title="Delete Project"
            size="lg"
            icon={solid("trash")}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="mt-8 rounded border border-gray-200">
      <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 py-4 px-6">
        <div className="font-medium w-full">
          <TextInput hasLoading={true} isLoading={organisationNameTimeout} value={organisation.name} onChange={handleNameChange} />
        </div>
        <div title="Add Project" className="border border-black bg-black text-white hover:text-black hover:bg-white transition-all duration-200 ease-in-out py-2 px-3 flex items-center flex-row ml-6  cursor-pointer" onClick={handleAddProject}>
          <FontAwesomeIcon
            icon={solid("plus")}
            size="sm"
          />
          <span className="ml-1">Project</span>
        </div>
        <div className="ml-6">
          <FontAwesomeIcon
            onClick={handleDeleteOrganisation}
            className="text-red-500 cursor-pointer"
            title="Delete Organisation"
            size="lg"
            icon={solid("trash")}
          />
        </div>
      </div>
      {organisation.projects && organisation.projects.length > 0 && (
        <div className="divide-y divide-inherit">
          {organisation.projects.map((project) => (
            <Project key={project.id} project={project} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Organisation;
