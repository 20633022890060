function NotFound() {
  return (
    <section className="body-font relative bg-white text-black">
      <div className="container mx-auto px-5 py-24">
        <div className="mb-12 flex w-full flex-col text-center">
          <h1 className="title-font mb-4 text-2xl font-medium text-black sm:text-3xl">
            404
          </h1>
          <p className="mx-auto text-lg leading-relaxed sm:text-xl lg:w-1/2">
            The page you requested could not be found.
          </p>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
