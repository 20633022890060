import React from "react";
import eventBus from "../eventBus";

function EmojiSelector({ value, uid, emojiChanged }) {

    const openEmojiModal = () => {
        eventBus.dispatch("displayEmojiModal", {uid: uid});
        eventBus.on("emojiSelected", (e) => {
            if (e.uid === uid) {
                value = e.emoji;
                emojiChanged(value);
            }
        });
    }

    return (
        <>
            <div onClick={openEmojiModal} className={`cursor-pointer text-center pt-[6px] ml-2 border border-dashed border-gray-400 rounded w-[48px]`}>
                {value}
            </div>
        </>
    );
}

export default EmojiSelector;