import React from "react";
import TextInput from "./text-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import EmojiSelector from "./emoji-selector";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loading from "./loading";

function ReorderList({
  label,
  items,
  minimumItems,
  isLoading,
  onChange,
  onItemChange,
  onAddItem,
  onDeleteItem,
}) {
  const { t } = useTranslation();

  return (
    <section className="flex flex-col">
      <label className="text-sm leading-7 text-gray-500">{label}</label>
      <div className="flex flex-col gap-2 border border-gray-400 py-3">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <DragDropContext onDragEnd={onChange}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <ul
                    className="drag-area"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        key={String(item.id)}
                        draggableId={String(item.id)}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            className={`relative mx-4 my-5 flex flex-row gap-3 border border-gray-300 bg-white px-2 py-3 text-gray-600`}
                          >
                            <div className="mb-auto flex w-full flex-col px-3">
                              <div className="flex flex-col">
                                <label className="text-sm leading-7 text-gray-500">
                                  Name
                                </label>
                                <TextInput
                                  value={item.name || ""}
                                  onChange={(e) => {
                                    onItemChange(
                                      item.id,
                                      "name",
                                      e.target.value,
                                    );
                                  }}
                                />
                              </div>
                              <div className="ml-1 mt-2 flex flex-row">
                                <label className="text-sm leading-7 text-gray-500">
                                  Icon
                                </label>
                                <EmojiSelector
                                  value={item.icon}
                                  uid={label + item.id}
                                  emojiChanged={(e) => {
                                    onItemChange(item.id, "icon", e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="mb-auto flex flex-col">
                              <div
                                className="drag-handle"
                                {...provided.dragHandleProps}
                              >
                                <FontAwesomeIcon
                                  title={t("drag_to_reorder")}
                                  icon={solid("bars")}
                                  size="xl"
                                  className="mx-2 mt-1"
                                />
                              </div>
                            </div>
                            {item.can_delete && item.can_delete === true && (
                              <div className="absolute bottom-0 right-0 p-2.5">
                                <FontAwesomeIcon
                                  title={t("delete")}
                                  icon={solid("trash-alt")}
                                  size="lg"
                                  className="mx-2 mb-1 cursor-pointer text-red-600"
                                  onClick={(e) => {
                                    if (
                                      minimumItems &&
                                      items.length <= minimumItems
                                    ) {
                                      toast.error(
                                        t(
                                          "drag_drop_list.error.minimum_items",
                                          { minimum: minimumItems },
                                        ),
                                      );
                                      return;
                                    }
                                    onDeleteItem(item.id);
                                  }}
                                />
                              </div>
                            )}
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <div className="my-3 flex flex-col px-3">
              <div className="m-auto">
                <FontAwesomeIcon
                  title={t("add")}
                  icon={solid("circle-plus")}
                  size={"xl"}
                  className={`cursor-pointer text-green-700`}
                  onClick={(e) => {
                    onAddItem();
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default ReorderList;
