import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import FormButton from "../components/form-button";
import SelectInput from "../components/select-input";
import { useSanctum } from "react-sanctum";
import { useNavigate } from "react-router-dom";
import ApiService from "../api";
import TextInput from "../components/text-input";
import TextAreaInput from "../components/textarea-input";
import FileUploadInput from "../components/file-upload-input";
import Markdown from "../components/markdown";
import BackButton from "../components/back-button";
import Loading from "../components/loading";

function CreateTicket() {
  const { authenticated } = useSanctum();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const [ticketTypes, setTicketTypes] = useState([]);

  const [currentStep, setCurrentStep] = useState(1);
  const [type, setType] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [organisationsLoading, setOrganisationsLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [creatingTicket, setCreatingTicket] = useState(false);

  const [subject, setSubject] = useState("");
  const [files, setFiles] = useState();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (authenticated === false) {
      navigate("/login");
    }

    ApiService.getTicketTypes().then((r) => {
      setTicketTypes(r);
    });
  }, [authenticated]);

  useEffect(() => {
    ApiService.getOrganisations().then((r) => {
      let orgs = r.map((org) => {
        return {
          value: org.id,
          label: org.name,
        };
      });
      setOrganisationOptions(orgs);
      if (orgs.length > 0) {
        setOrganisation(orgs[0].value);
      }
      setOrganisationsLoading(false);
    });
  }, [type]);

  useEffect(() => {
    if (organisation) {
      setProjectsLoading(true);
      ApiService.getProjects(organisation).then((r) => {
        let prjs = r.map((project) => {
          return {
            value: project.id,
            label: project.name,
          };
        });
        setProjectOptions(prjs);
        if (prjs.length > 0) {
          setProject(prjs[0].value);
        }
        setProjectsLoading(false);
      });
    }
  }, [organisation]);

  function handleTicketType(type) {
    setType(type);
    setCurrentStep(2);
  }
  function handlePreviousStep() {
    if (currentStep === 1) {
      return;
    }
    if (currentStep === 2) {
      setType(null);
    }
    setCurrentStep(currentStep - 1);
  }

  function handleCreateTicket() {
    setCreatingTicket(true);
    ApiService.createTicket(
      type,
      subject,
      organisation,
      project,
      message.trim(),
      files,
    )
      .then((r) => {
        navigate("/tickets/" + r.id);
        setCreatingTicket(false);
      })
      .catch((e) => {
        setCreatingTicket(false);
      });
  }

  if (organisationsLoading && projectsLoading) {
    return (
      <section className="body-font relative bg-white text-black">
        <div className="container mx-auto px-5 py-24">
          <BackButton h1_name={"dashboard"} />
          <div className="mx-auto flex flex-col">
            <h1 className="title-font mb-5 text-2xl font-medium text-black sm:text-3xl">
              {t("create_ticket.h1")}
            </h1>
            <div className="flex flex-col">
              <Loading />
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (
    (organisationOptions.length === 0 || projectOptions.length === 0) &&
    (!organisationsLoading || !projectsLoading)
  ) {
    return (
      <section className="body-font relative bg-white text-black">
        <div className="container mx-auto px-5 py-24">
          <BackButton h1_name={"dashboard"} />
          <div className="mx-auto flex flex-col">
            <h1 className="title-font mb-5 text-2xl font-medium text-black sm:text-3xl">
              {t("create_ticket.h1")}
            </h1>
            <div className="flex flex-col">
              <p className={"mb-5"}>
                {t("create_ticket.no_organisations_or_projects")}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (currentStep === 1) {
    return (
      <section className="body-font relative bg-white text-black">
        <div className="container mx-auto px-5 py-24">
          <BackButton h1_name={"dashboard"} />
          <h1 className="title-font mb-5 text-2xl font-medium text-black sm:text-3xl">
            {t("create_ticket.h1")}
          </h1>
          <p className={"mb-5"}>{t("create_ticket.ticket_type_question")}</p>
          <div className="flex flex-col gap-2 md:flex-row">
            {ticketTypes.map((ticketType) => (
              <FormButton
                key={ticketType.id}
                isLoading={organisation === null || project === null}
                onClick={() => handleTicketType(ticketType.id)}
                label={ticketType.icon + " " + ticketType.name}
              />
            ))}
          </div>
        </div>
      </section>
    );
  } else if (currentStep === 2) {
    return (
      <section className="body-font relative bg-white text-black">
        <div className="container mx-auto px-5 py-24">
          <BackButton h1_name={"dashboard"} />
          <div className="mx-auto flex flex-col gap-4">
            <h1 className="title-font mb-5 text-2xl font-medium text-black sm:text-3xl">
              {t("create_ticket.h1")}
            </h1>
            <TextInput
              required={true}
              label={t("create_ticket.subject")}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder={t("create_ticket.subject_placeholder")}
            />
            <SelectInput
              onChange={(e) => setOrganisation(e.target.value)}
              label={t("create_ticket.select_organisation")}
              options={organisationOptions}
            />
            <SelectInput
              disabled={organisation === null || projectsLoading}
              label={t("create_ticket.select_project")}
              options={projectOptions}
            />
            <div className={"mt-8 flex flex-row"}>
              <FormButton
                onClick={() => handlePreviousStep()}
                label={t("previous")}
              />
              <div className={"flex-grow"}>&nbsp;</div>
              <FormButton
                disabled={
                  !subject ||
                  projectsLoading ||
                  organisation === null ||
                  project === null
                }
                onClick={() => setCurrentStep(3)}
                label={t("next")}
              />
            </div>
          </div>
        </div>
      </section>
    );
  } else if (currentStep === 3) {
    return (
      <section className="body-font relative bg-white text-black">
        <div className="container mx-auto px-5 py-24">
          <BackButton h1_name={"dashboard"} />
          <div className="mx-auto flex flex-col gap-4">
            <h1 className="title-font mb-5 text-2xl font-medium text-black sm:text-3xl">
              {t("create_ticket.h1")}
            </h1>
            <p className={""}>{t("create_ticket.other_enquiry_message")}</p>
            <Markdown
              label={t("create_ticket.message")}
              value={message}
              onChange={setMessage}
            />
            <div className={"flex flex-row"}>
              <FormButton
                disabled={creatingTicket}
                onClick={() => handlePreviousStep()}
                label={t("previous")}
              />
              <div className={"flex-grow"}>&nbsp;</div>
              <FormButton
                isLoading={creatingTicket}
                onClick={handleCreateTicket}
                label={t("create_ticket.h1")}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CreateTicket;
