import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ApiService from "../../api";
import ReorderList from "../../components/reorder-list";
import SelectInput from "../../components/select-input";
import { useDebouncedCallback } from "use-debounce";

function TicketTypes({ isLoading, setIsLoading, ticketTypes, setTicketTypes }) {
  const { t } = useTranslation();

  const syncWithServer = useDebouncedCallback((list) => {
    ApiService.updateTicketTypes(list).then((response) => {
      setIsLoading(false);
    });
  }, 600);

  const handleReorder = (list, setList) => (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      list,
      result.source.index,
      result.destination.index,
    );

    setList(newItems);
    syncWithServer(newItems);
  };

  const handleItemChange = (list, setList) => (id, field, value) => {
    const newItems = list.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });
    setList(newItems);
    syncWithServer(newItems);
  };

  const handleAddItem = (list, setList) => () => {
    setIsLoading(true);
    ApiService.addTicketType().then((response) => {
      setList([...list, response]);
      setIsLoading(false);
    });
  };

  const handleDeleteItem = (list, setList) => (id) => {
    ApiService.deleteTicketType(id).then((response) => {
      const newItems = list.filter((item) => {
        return item.id !== id;
      });
      setList(newItems);
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <div className="flex flex-col gap-5 pt-8">
      <ReorderList
        isLoading={isLoading}
        label={t("admin.ticket_types")}
        items={ticketTypes}
        minimumItems={1}
        onChange={handleReorder(ticketTypes, setTicketTypes)}
        onItemChange={handleItemChange(ticketTypes, setTicketTypes)}
        onAddItem={handleAddItem(ticketTypes, setTicketTypes)}
        onDeleteItem={handleDeleteItem(ticketTypes, setTicketTypes)}
      />
    </div>
  );
}

export default TicketTypes;
