import TabItem from "./tab-item";

function TabGroup({ tabs, selected, onTabClick }) {
  return (
    <ul className="flex flex-wrap text-sm font-medium text-center border-b border-black">
        {tabs.map((tab, index) => (
            <TabItem key={tab.label} label={tab.label} selected={selected === index} onClick={() => onTabClick(index)} />
        ))}
    </ul>
  );
}

export default TabGroup;