
function SelectInput({ id, name, label, options, value, onChange, disabled }) {
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <label htmlFor={id} className="leading-7 text-sm text-gray-500">{label}</label>
        <select disabled={disabled} className={`${disabled ? 'bg-gray-100' : ''} w-full bg-white border border-gray-400 focus:border-black text-base outline-none text-black py-2 px-3 leading-8 transition-colors duration-200 ease-in-out`} id={id} name={name} value={value} onChange={onChange}>
          {(Array.isArray(options) && options.length > 0) &&
            options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          }
        </select>
      </div>
    </div>
  );
}

export default SelectInput;