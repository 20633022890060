/* eslint-disable react-hooks/exhaustive-deps */
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Loading from "../components/loading";
import Pagination from "../components/pagination";
import ApiService from "../api";
import {useSanctum} from "react-sanctum";
import TicketCard from "../components/ticket-card";
import {SwitchTransition, CSSTransition} from "react-transition-group";
import SelectInput from "../components/select-input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

function Dashboard() {
  const { authenticated } = useSanctum();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [initialised, setInitialised] = useState(false);
  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [assigneeFilter] = useState({
    name: t('assignee'),
    options: [
      {
        label: t('all'),
        value: '',
      },
      {
        label: t('my_own'),
        value: 'own',
      },
      {
        label: t('others'),
        value: 'others',
      }
    ]
  });
  const [selectedAssignee, setSelectedAssignee] = useState('');

  const [statusFilter, setStatusFilter] = useState({
    name: t('status'),
    options: []
  });
  const [selectedStatus, setSelectedStatus] = useState('');

  const [organisationFilter, setOrganisationFilter] = useState({
    name: t('organisation'),
    options: []
  });
  const [selectedOrganisation, setSelectedOrganisation] = useState('');

  const [projectFilter, setProjectFilter] = useState({
    name: t('project'),
    options: []
  });
  const [selectedProject, setSelectedProject] = useState('');

  useEffect(() => {
    if (authenticated === false) {
      navigate('/login');
    }
  }, [authenticated]);

  useEffect(() => {
    async function initialise() {
      let filterPromise = [];
      filterPromise.push(loadStatusFilter());
      filterPromise.push(loadOrganisationFilter());
      filterPromise.push(loadProjectFilter());
      Promise.allSettled(filterPromise).then(() => {
        setInitialised(true);
      });
    }
    authenticated && initialise();
  }, [authenticated]);

  useEffect(() => {
    setProjectFilter({...projectFilter, 'options': []});
    if (organisationFilter.options.find(option => option.selected)) {
      loadProjectFilter(organisationFilter.options.find(option => option.selected).value);
    }
  }, [organisationFilter]);

  useEffect(() => {
    if (!isLoadingTickets && initialised) {
      setIsLoadingTickets(true);
      loadTickets();
    }
  }, [selectedAssignee, selectedStatus, selectedOrganisation, selectedProject, currentPage]);

  useEffect(() => {
    if (!isLoadingTickets && initialised) {
      setIsLoadingTickets(true);
      loadTickets();
    }
  }, [initialised]);

  async function loadStatusFilter() {
    ApiService.getTicketStatuses().then(r => {
      const filters = [
        {
          label: t('all'),
          value: '',
        }
      ];
      if (r.length > 0) {
        r.forEach(s => {
          filters.push({
            label: s.icon + ' ' + s.name,
            value: String(s.id)
          });
        });
      }
      setStatusFilter({...statusFilter, 'options': filters});
    }).catch(err => {
      if (err.response && err.response.status && (err.response.status !== 401 && err.response.status !== 403)) {
        toast.error(t('api.general_error'));
      }
    });
  }

  async function loadOrganisationFilter() {
    ApiService.getOrganisations().then(r => {
      const filters = [{
        label: t('all'),
        value: '',
      }];
      if (r.length > 0) {
        r.forEach(o => {
          filters.push({
            label: o.name,
            value: String(o.id),
          });
        });
      }
      setOrganisationFilter({...organisationFilter, 'options': filters});
    }).catch(err => {
      if (err.response && err.response.status && (err.response.status !== 401 && err.response.status !== 403)) {
        toast.error(t('api.general_error'));
      }
    });
  }

  async function loadProjectFilter(organisationId = null) {
    if (organisationId !== null && organisationId !== '') {
      ApiService.getProjects(organisationId).then(r => {
        const filters = [];
        if (r.length > 0) {
          filters.push({
            label: t('all'),
            value: '',
          });
          r.forEach(p => {
            filters.push({
              label: p.name,
              value: String(p.id),
            });
          });
        }
        setProjectFilter({...projectFilter, 'options': filters});
      }).catch(err => {
        if (err.response && err.response.status && (err.response.status !== 401 && err.response.status !== 403)) {
          toast.error(t('api.general_error'));
        }
      });
    }
  }

  function loadTickets() {
    setTickets([]);
    ApiService.getTickets(selectedAssignee, selectedStatus, selectedOrganisation, selectedProject).then(r => {
      setTickets(r);
      setIsLoadingTickets(false);
      setInitialised(true);
    })
  }

  function handlePageChange(page) {
    if (page > 0 && page <= 10) {
      setCurrentPage(page);
    }
  }

  function TicketList(data = []) {
    if (data.data && data.data.length > 0) {
      return data.data.map((ticket, index) => {
        return (<TicketCard key={index} ticket={ticket}/>)
      });
    } else {
      return (<div className="text-center">
        <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">{t('dashboard.no_tickets')}</p>
      </div>);
    }
  }

  return (
    <section className="text-black bg-white body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col sm:flex-row sm:items-center items-start sm:justify-between justify-start mb-8 md:mb-4">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-black">{t("dashboard.h1")}</h1>
          <Link to={'/create-ticket'} className="text-white bg-black w-full mt-5 sm:mt-0 sm:w-48 text-base border border-black pb-1 pt-1.5 px-8 focus:outline-none hover:bg-white hover:text-black transition-all duration-200 ease-in-out text-center">
            {t("dashboard.create_ticket")}
          </Link>
        </div>

        <div className="flex flex-col">

          <div className="">
            <div className="grid grid-cols-4 mt-1">
              <div className="col-span-4 md:col-span-2 lg:col-span-1 md:pr-2 mb-2">
                <SelectInput label={assigneeFilter.name} options={assigneeFilter.options} onChange={e => {setSelectedAssignee(e.target.value)}}></SelectInput>
              </div>
              <div className="col-span-4 md:col-span-2 lg:col-span-1 lg:pr-2 mb-2">
                <SelectInput disabled={statusFilter.options.length === 0} label={statusFilter.name} options={statusFilter.options} onChange={e => {setSelectedStatus(e.target.value)}}></SelectInput>
              </div>
              <div className="col-span-4 md:col-span-2 lg:col-span-1 md:pr-2 mb-2">
                <SelectInput disabled={organisationFilter.options.length === 0} label={organisationFilter.name} options={organisationFilter.options} onChange={e => {setSelectedOrganisation(e.target.value)}}></SelectInput>
              </div>
              <div className="col-span-4 md:col-span-2 lg:col-span-1 mb-2">
                <SelectInput disabled={projectFilter.options.length === 0} label={projectFilter.name} options={projectFilter.options} onChange={e => {setSelectedProject(e.target.value)}}></SelectInput>
              </div>
            </div>
          </div>

          <div className="flex-grow mt-9">
            <div className="flex flex-col sm:flex-row sm:items-center items-start sm:justify-between justify-start">
              <h2 className={'text-lg'}>{t('tickets')}</h2>
            </div>

            <div className="flex justify-center">
              <div className="mt-2 w-full mb-2">
                <div className="relative mb-4 flex w-full flex-wrap items-stretch">
                  <input
                    type="search"
                    className="relative m-0 -mr-px block w-[1%] min-w-0 flex-auto bg-white border border-gray-400 focus:border-black text-base outline-none text-black py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="button-addon1" />
                  <button
                    className="relative z-[2] flex items-center rounded-r bg-black px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white transition duration-150 ease-in-out border border-black hover:border-gray-400 hover:bg-white hover:text-black"
                    type="button"
                    id="button-addon1"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    <FontAwesomeIcon size="lg" icon={solid('search')} />
                  </button>
                </div>
              </div>
            </div>

            <SwitchTransition>
              <CSSTransition key={isLoadingTickets} classNames="fade" timeout={300}>
                <React.Fragment>
                  
                {isLoadingTickets || !initialised ? (
                  <Loading />
                ) : (
                  <React.Fragment>
                    <TicketList data={tickets.data} />
                    <Pagination current_page={currentPage} total_pages={tickets.total || 0} onChange={handlePageChange}/>
                  </React.Fragment>
                )}

                </React.Fragment>
              </CSSTransition>
            </SwitchTransition>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Dashboard;