
function Emoji (props) {
  const decodedSymbol = (new DOMParser().parseFromString(props.symbol, "text/html")).body.textContent;
  return (<span
    className="emoji mr-1"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {decodedSymbol}
    </span>)
}
export default Emoji;