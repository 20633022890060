
function FileUploadInput({id, label, name, value, onChange, disabled}) {
  return (
    <div className="p-0 w-full">
      <div className="flex flex-col">
        <div className="mb-3 w-full">
          <label htmlFor={id} className="leading-7 text-sm text-gray-500">{label}</label>
          <input
            className="text-gray-500 relative m-0 block w-full min-w-0 min-h-[42px] flex-auto cursor-pointer rounded bg-white border border-gray-400 focus:border-black text-base outline-none bg-clip-padding px-3 py-[5px] text-base transition duration-300 ease-in-out file:-mx-3 file:min-h-[42px] file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-1.5 file:text-black file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:bg-white focus:text-gray-500 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
            type="file"
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            id={id} />
        </div>
      </div>
    </div>
  );
}

export default FileUploadInput;