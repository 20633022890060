import { Link } from "react-router-dom";

function Foot() {
  return (
    <footer className={`bg-black text-white body-font`}>
      <div className="container px-5 py-4 mx-auto flex items-center flex-col">
        <p className="text-sm text-gray-400 my-3">
          <Link to={"https://welfordmedia.co.uk"}>&copy; 2023 Welford Media Limited</Link>
        </p>
      </div>
    </footer>
  );
}

export default Foot;