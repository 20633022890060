import Loading from "../../components/loading";
import Organisation from "../../components/organisation";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ApiService from '../../api';

function Organisations() {

  const { t } = useTranslation(); // Hook to get the translation function

  const [organisations, setOrganisations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrganisations();
  }, []);

  const setOrganisationName = (id, value) => {
    const updatedOrganisations = organisations.map((organisation) => {
      if (organisation.id === id) {
        return { ...organisation, name: value };
      }
      return organisation;
    });
    setOrganisations(updatedOrganisations);
  };

  const addProjectToOrganisation = (organisationId) => {
    createProject(organisationId);
  };

  const createOrganisation = () => {
    setLoading(true);
    ApiService.createOrganisation("New Organisation").then((response) => {
      getOrganisations();
    }).catch(() => {
      setLoading(false);
    });
  }

  const deleteOrganisation = (organisation_id) => {
    setLoading(true);
    ApiService.deleteOrganisation(organisation_id).then((response) => {
      getOrganisations();
    }).catch(() => {
      setLoading(false);
    });
  }

  const createProject = (organisation_id) => {
    setLoading(true);
    ApiService.createProject("New Project", organisation_id).then((response) => {
      getOrganisations();
    }).catch(() => {
      setLoading(false);
    });
  }

  const deleteProject = (project_id) => {
    setLoading(true);
    ApiService.deleteProject(project_id).then((response) => {
      getOrganisations();
    }).catch(() => {
      setLoading(false);
    });
  }

  const getOrganisations = () => {
    setLoading(true);
    ApiService.getOrganisations().then((response) => {
      setOrganisations(response);
      setLoading(false);
    });
  }

  if (loading) {
    return <div className="my-8"><Loading /></div>;
  }

  return (
    <>
      <div className="mt-8 flex justify-end">
        <button onClick={createOrganisation} className="border border-black bg-black text-white hover:text-black hover:bg-white transition-all duration-200 ease-in-out px-4 py-2">
          {t("admin.add-organisation")}
        </button>
      </div>
      {organisations.map((organisation) => (
        <Organisation key={organisation.id} deleteOrganisation={deleteOrganisation} organisation={organisation} setOrganisationName={setOrganisationName} addProject={addProjectToOrganisation} deleteProject={deleteProject} />
      ))}
    </>
  );
}

export default Organisations;
