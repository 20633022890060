import { createSlice } from "@reduxjs/toolkit";

export const brandingSlice = createSlice({
    name: "branding",
    initialState: {
        workgroup: "",
        logo: "",
        colors: {
            'button-bg-color': 'bg-black',
            'button-text-color': 'text-white',
            'button-border-color': 'border-black',
            'button-hover-bg-color': 'bg-white',
            'button-hover-text-color': 'text-black',
            'button-hover-border-color': 'border-black',
            'header-bg-color': 'bg-black',
            'header-text-color': 'text-white',
            'footer-bg-color': 'bg-black',
            'footer-text-color': 'text-white',
        }
    },
    reducers: {
        setBranding: (state, action) => {
            state.workgroup = action.payload.workgroup;
            state.logo = action.payload.urls.logo;
            state.colors = action.payload.colors;
        },
    }
});

export const { setBranding } = brandingSlice.actions;

export default brandingSlice.reducer;