import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useCallback, useState} from "react";
import {useSanctum} from "react-sanctum";
import {useSelector} from "react-redux";
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import AuthorisedComponent from './authorised-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function Nav (props) {
  const branding = useSelector(state => state.branding)
  const { authenticated, signOut } = useSanctum();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [languages] = useState(() => {
    return i18n.languages.map(l => {
      return {code: l, name: t(`languages.${l}`)};
    });
  });

  const logOut = useCallback(() => {
    signOut().then(() => {
      navigate('/login');
    });
  }, []);

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value).then(r => window.localStorage.setItem('language', e.target.value));
  }

  const AdminButton = () => {
    return (
      <Link title={t('admin')} to={'/admin'}
        className="select-none hover:cursor-pointer inline-flex items-center text-black bg-white border border-white text-sm sm:text-base py-3 sm:py-2 px-3 focus:outline-none hover:bg-black hover:text-white transition-all duration-200 ease-in-out">
        <span className='sm:mr-2 hidden sm:block'>{t('admin')}</span>
        <FontAwesomeIcon className='text-xl sm:text-sm' title={t('admin')} icon={solid('cog')} />
      </Link>
    );
  }

  return (
    <header className={`bg-black text-white`}>
      <div className="p-5 flex flex-row items-center">
        <Link to={authenticated ? '/dashboard' : '/'}>
          <SwitchTransition>
            <CSSTransition key={branding.logo} classNames='fade' timeout={300}>
              <img key={'logo'} src={branding.logo} alt={branding.workgroup} />
            </CSSTransition>
          </SwitchTransition>
        </Link>
        <div className='flex-grow'></div>
        <div className="flex flex-row gap-x-2">
          {authenticated === true &&
            <AuthorisedComponent requiredRole={'admin'} component={AdminButton} />
          }
          {authenticated === false &&
            <Link title={t('login')} to={'/login'}
              className="inline-flex items-center text-black bg-white border border-white text-sm sm:text-base py-3 sm:py-2 px-3 focus:outline-none hover:bg-black hover:text-white transition-all duration-200 ease-in-out">
              <span className='sm:mr-2 hidden sm:block'>{t('login')}</span>
              <FontAwesomeIcon className='text-xl sm:text-sm' title={t('login')} icon={solid('sign-in-alt')} />
            </Link>
          }
          {authenticated === true && (
            <div title={t('logout')} onClick={logOut}
              className="select-none hover:cursor-pointer inline-flex items-center text-black bg-white border border-white text-sm sm:text-base py-3 sm:py-2 px-3 focus:outline-none hover:bg-black hover:text-white transition-all duration-200 ease-in-out">
              <span className='sm:mr-2 hidden sm:block'>{t('logout')}</span>
              <FontAwesomeIcon className='text-xl sm:text-sm' title={t('logout')} icon={solid('sign-out-alt')} />
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Nav;