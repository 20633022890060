
function Pagination({current_page, total_pages, onChange}) {
  const getPageRange = (current_page, total_pages) => {
    if (current_page === 1) {
      return [1, Math.min(3, total_pages)];
    }
    if (current_page === total_pages) {
      return [Math.max(1, total_pages - 2), total_pages];
    }
    return [current_page - 1, current_page + 1];
  };

  const [start_page, end_page] = getPageRange(current_page, total_pages);

  function handleChange(e) {
    onChange(e);
  }

  function handlePrevious(e) {
    if (current_page > 1) {
      onChange(current_page - 1);
    }
  }

  function handleNext(e) {
    if (current_page < total_pages) {
      onChange(current_page + 1);
    }
  }

  if (total_pages === 0) { return <div>&nbsp;</div> };

  return (
    <div className="flex justify-center mt-9">
      <nav aria-label="Page navigation example">
        <ul className="flex list-style-none">
          <li className="page-item select-none"><div
            className="hover:cursor-pointer page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 text-gray-500 hover:bg-black hover:text-white hover:border-white focus:shadow-none"
            onClick={handlePrevious} aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </div></li>

          {Array.from({ length: end_page - start_page + 1 }, (v, k) => k + start_page).map(
            (page, index) => {
              return (
                <li className="page-item select-none hover:cursor-pointer" key={index}>
                  <div
                    className={`${
                      current_page === page
                        ? "bg-black text-white"
                        : "text-gray-500 hover:text-white hover:bg-black hover:border-white"
                    } page-link relative block py-1.5 px-3 outline-none transition-all duration-300 border focus:shadow-none`}
                    onClick={(e) => {
                      handleChange(page);
                    }}
                  >
                    {page}
                  </div>
                </li>
              );
            }
          )}

          <li className="page-item select-none"><div
            className="hover:cursor-pointer page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 text-gray-500 hover:bg-black hover:text-white hover:border-white focus:shadow-none"
            onClick={handleNext} aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </div></li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;