import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

function TextInput({id, name, placeholder, label, value, onChange, disabled, type, hasLoading, isLoading}) {
  return (
    <div className="w-full">
      <div className="relative">
        <label htmlFor={id} className="leading-7 text-sm text-gray-500">{label}</label>
        <input placeholder={placeholder} disabled={disabled} onChange={e => onChange(e)} defaultValue={String(value)} type={type} id={id} name={name} className={`${disabled ? 'bg-gray-100' : ''} w-full bg-white border border-gray-400 focus:border-black text-base outline-none text-black py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`} />
        {hasLoading && isLoading && (
          <div title="Saving" className="absolute right-0 top-0 mt-[9px] mr-3 text-gray-500">
            <div className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              </span>
            </div>
          </div>
        )}
        {hasLoading && !isLoading && (
          <div className="absolute right-0 top-0 mt-[9px] mr-3 text-green-500">
            <FontAwesomeIcon
              title="Saved"
              size="lg"
              icon={solid("check")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TextInput;