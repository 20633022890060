import TextInput from "../components/text-input";
import FormButton from "../components/form-button";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {useSanctum} from "react-sanctum";

function Login() {
  const { authenticated, signIn } = useSanctum();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (authenticated === true) {
      navigate("/dashboard");
    }
  }, [authenticated]);

  function handleSubmit(event) {
    event.preventDefault();
    let errs = validateForm();
    if (errs.length > 0) {
      errs.forEach(err => toast.error(err));
      return;
    }
    setIsLoading(true);
    signIn(email, password).then(r => {
      navigate("/dashboard");
    }).catch(e => {
      if (e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      }
      setIsLoading(false);
    })
  }

  function validateForm() {
    const errs = [];
    if (email.length < 1) {
      errs.push(t("login.email_error"));
    }
    if (password.length < 1) {
      errs.push(t("login.password_error"));
    }
    return errs;
  }

  return (
    <section className="text-black bg-white body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-black">{t("login.h1")}</h1>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <TextInput id="email" name="email" label={t("email")} value={email} type={"email"} onChange={e => setEmail(e.target.value)} disabled={isLoading} />
            <TextInput id="password" name="password" label={t("password")} value={password} type={"password"} onChange={e => setPassword(e.target.value)} disabled={isLoading} />
            <Link to={"/forgot-password"} className={`text-sm text-gray-400`} >{t("login.forgot_password")}</Link>
            <div className="pt-4">
              <FormButton button_type={'submit'} label={t("login")} isLoading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;