
function TabItem({ label, selected, onClick }) {
    return (
        <li>
            <div onClick={onClick}
            className={`select-none cursor-pointer inline-block p-4 rounded-t-lg transition-all duration-200 ease-in-out border border-b-0 ${selected ? 'text-white bg-black border-black' : 'text-black bg-white border-white' }`}>
                {label}
            </div>
        </li>
    );
}

export default TabItem;